import React, { Component } from 'react';
import { Layout } from 'components';

class Index extends Component {
  render() {
    return (
      <Layout>
        <main className="mt-5">
          <div className="container">
            <h1>About Us</h1>
            <p>Something about us.</p>
          </div>
        </main>
      </Layout>
    );
  }
}

export default Index;
